import React from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

const ServicesOne = () => {
  return (
    <section className="solutions-area bg-f1f8fb pt-100 pb-70">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 pb-5">
            <p>
              HydraChain is a Proof of Stake blockchain network which offers predictable income to stakers. You can check out the{" "}
              <Link to="/staking-calculator" className="text-primary" color="primary">
                staking calculator
              </Link>{" "}
              to calculate your ROI. Becoming a node on HydraChain is quite simple, and it starts with{" "}
              <Link to="/wallet" className="text-primary">
                setting up your wallet client
              </Link>
              . The guides below explains in depth how to start your own node in different environments.
            </p>
          </div>
        </div>
        <div className="row p-5 mb-5 rounded" style={{ background: "rgba(255,255,255,0.5)" }}>
          <div className="col-lg-4 col-md-4 mb-4">
            <div className="single-solutions-box">
              <div className="icon">
                <StaticImage placeholder="blurred" className="mx-auto" layout="fixed" width={80} height={80} src="../../assets/images/icons/installation.png" alt="Installing your Staking Wallet" />
              </div>
              <h3>
                <Link to="/wallet">Installing your Staking Wallet</Link>
              </h3>
              <p>How to install your wallet for staking.</p>

              <Link className="view-details-btn" to="/wallet">
                View Guide
              </Link>
              <span className={"step-direction"}>
                <i className="flaticon-right"></i>
              </span>
            </div>
          </div>

          <div className="col-lg-4 col-md-4 mb-4">
            <div className="single-solutions-box">
              <div className="icon">
                <StaticImage placeholder="blurred" className="mx-auto" layout="fixed" width={80} height={80} src="../../assets/images/icons/configuring.png" alt="Setting up for Staking" />
              </div>
              <h3>
                <Link to="/wallet">Setting up for Staking</Link>
              </h3>
              <p>Configure your staking wallet correctly.</p>

              <Link className="view-details-btn" to="https://docs.hydrachain.org/staking-hydra-coins/setting-up-staking" target="_blank">
                View Guide
              </Link>
              <span className={"step-direction"}>
                <i className="flaticon-right"></i>
              </span>
            </div>
          </div>

          <div className="col-lg-4 col-md-4 mb-4">
            <div className="single-solutions-box">
              <div className="icon">
                <StaticImage placeholder="blurred" className="mx-auto" layout="fixed" width={80} height={80} src="../../assets/images/icons/learning.png" alt="Understanding Staking" />
              </div>
              <h3>
                <Link to="https://docs.hydrachain.org/staking-hydra-coins/how-the-staking-works" target="_blank">
                  Understanding Staking
                </Link>
              </h3>
              <p>Fundamentals of how staking works</p>
              <Link className="view-details-btn" to="https://docs.hydrachain.org/staking-hydra-coins/how-the-staking-works" target="_blank">
                View Guide
              </Link>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-4 col-md-6 col-sm-6 mb-4">
            <div className="single-solutions-box">
              <div className="icon">
                <StaticImage placeholder="blurred" className="mx-auto" layout="fixed" width={80} height={80} src="../../assets/images/icons/windows-vps.png" alt="Staking with Windows VPS on AWS" />
              </div>

              <h3>
                <Link to="https://docs.hydrachain.org/staking-hydra-coins/staking-in-the-cloud-with-aws" target="_blank">
                  Staking with Windows VPS on AWS
                </Link>
              </h3>

              <p>Guide to setup your staking on Windows VPS on AWS</p>

              <Link className="view-details-btn" to="https://docs.hydrachain.org/staking-hydra-coins/staking-in-the-cloud-with-aws" target="_blank">
                View Guide
              </Link>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 col-sm-6 mb-4">
            <div className="single-solutions-box">
              <div className="icon">
                <StaticImage placeholder="blurred" className="mx-auto" layout="fixed" width={80} height={80} src="../../assets/images/icons/linux-digital-ocean.png" alt="Staking with Linux on Digital Ocean VPS" />
              </div>

              <h3>
                <Link to="https://docs.hydrachain.org/staking-hydra-coins/staking-with-linux-digitalocean" target="_blank">
                  Staking with Linux on Digital Ocean VPS
                </Link>
              </h3>

              <p>Guide to setup your staking on Linux on Digital Ocean VPS</p>

              <Link className="view-details-btn" to="https://docs.hydrachain.org/staking-hydra-coins/staking-with-linux-digitalocean" target="_blank">
                View Guide
              </Link>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 col-sm-6 mb-4">
            <div className="single-solutions-box">
              <div className="icon">
                <StaticImage placeholder="blurred" className="mx-auto" layout="fixed" width={80} height={80} src="../../assets/images/icons/linux-vps.png" alt="Staking with Linux VPS" />
              </div>

              <h3>
                <Link to="https://docs.hydrachain.org/staking-hydra-coins/stake-with-linux-vps" target="_blank">
                  Staking with Linux VPS
                </Link>
              </h3>

              <p>Guide to setup your staking on Linux VPS</p>

              <Link className="view-details-btn" to="https://docs.hydrachain.org/staking-hydra-coins/stake-with-linux-vps" target="_blank">
                View Guide
              </Link>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 col-sm-6 mb-4">
            <div className="single-solutions-box">
              <div className="icon">
                <StaticImage placeholder="blurred" className="mx-auto" layout="fixed" width={80} height={80} src="../../assets/images/icons/linux-os.png" alt="Staking Hydra on Linux" />
              </div>

              <h3>
                <Link to="https://docs.hydrachain.org/staking-hydra-coins/how-to-stake-with-hydra" target="_blank">
                  Staking Hydra on Linux
                </Link>
              </h3>

              <p>Guide to setup your staking on Linux OS</p>

              <Link className="view-details-btn" to="https://docs.hydrachain.org/staking-hydra-coins/how-to-stake-with-hydra" target="_blank">
                View Guide
              </Link>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 col-sm-6 mb-4">
            <div className="single-solutions-box">
              <div className="icon">
                <StaticImage placeholder="blurred" className="mx-auto" layout="fixed" width={80} height={80} src="../../assets/images/icons/free-bsd.png" alt="Staking Hydra on Free BSD" />
              </div>

              <h3>
                <Link to="https://docs.hydrachain.org/staking-hydra-coins/how-to-stake-on-freebsd" target="_blank">
                  Staking Hydra on Free BSD
                </Link>
              </h3>

              <p>Guide to setup your staking on Free BSD OS</p>

              <Link className="view-details-btn" to="https://docs.hydrachain.org/staking-hydra-coins/how-to-stake-on-freebsd" target="_blank">
                View Guide
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ServicesOne;
