import React from "react";
import Layout from "../components/App/Layout";
import Navbar from "../components/App/Navbar";
import PageBanner from "../components/Common/PageBanner";
import Footer from "../components/App/Footer";
import BecomingANode from "../components/Wallet/BecomingANode";
import { Helmet } from "react-helmet";

const BecomingANodePage = ({ location }) => {
  return (
    <Layout>
      <Helmet>
        <title>How to become a Node | HydraChain</title>
        <link rel="canonical" href={`https://hydrachain.org/${location.pathname}`} />
      </Helmet>
      <Navbar />
      <PageBanner pageTitle="Becoming a Node" homePageText="Home" homePageUrl="/" activePageText="Becoming a Node" />
      <BecomingANode />
      <Footer />
    </Layout>
  );
};

export default BecomingANodePage;
