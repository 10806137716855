import React from "react";
import { Link } from "gatsby";
import logo from "../../assets/images/logo.png";
import footerMap from "../../assets/images/footer-map.png";

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="footer-area bg-color">
      <div className="container">
        <div className="row">
          <div className="col-lg-3 col-sm-6">
            <div className="single-footer-widget text-center text-sm-left">
              <a href="/" className="logo">
                <img src={logo} alt="logo" />
              </a>

              <ul className="social-link">
                <li>
                  <a
                    href="https://t.me/hydrachain"
                    className="d-block"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="bx bxl-telegram"></i>
                  </a>
                </li>
                <li>
                  <a
                    href="https://twitter.com/hydra_chain"
                    className="d-block"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="bx bxl-twitter"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-9 col-sm-6">
            <div
              className="row p-3"
              style={{ flexDirection: "row", justifyContent: "space-between" }}
            >
              <div className="">
                <div className="single-footer-widget">
                  <h5>About</h5>

                  <ul className="footer-links-list">
                    <li>
                      <Link to="/projects">Projects</Link>
                    </li>
                    <li>
                      <Link to="https://hydrachain.org/vision" target="_blank">
                        Vision
                      </Link>
                    </li>
                    <li>
                      <Link to="/the-team">Team</Link>
                    </li>
                    <li>
                      <Link to="https://t.me/hydrachain" target="_blank">
                        Community
                      </Link>
                    </li>
                    <li>
                      <Link to="https://t.me/Hydrachain_News" target="_blank">
                        News Channel
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="">
                <div className="single-footer-widget pl-5">
                  <h5>Staking</h5>

                  <ul className="footer-links-list">
                    <li>
                      <Link to="/staking-economy">Staking Economy</Link>
                    </li>
                    <li>
                      <Link to="/becoming-a-node">Becoming a Node</Link>
                    </li>
                    <li>
                      <Link to="/staking-calculator">Staking Calculator</Link>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="">
                <div className="single-footer-widget">
                  <h5>Essentials</h5>

                  <ul className="footer-links-list">
                    <li>
                      <Link to="/wallet">Desktop Client</Link>
                    </li>
                    <li>
                      <Link
                        to="https://webwallet.hydrachain.org/"
                        target="_blank"
                      >
                        Web Wallet
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="https://explorer.hydrachain.org/"
                        target="_blank"
                      >
                        Block Explorer
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="https://docs.google.com/document/d/e/2PACX-1vReUzdGzkc3Plm-VbLv2TGWeJ81dMmraKczCaUVRBDix3s4PROipcJ2aDQ1U_bZruU_OplXVQqCCPbe/pub"
                        target="_blank"
                      >
                        White Paper
                      </Link>
                    </li>
                    <li>
                      <Link to="https://hydradex.org/#/swap" target="_blank">
                        Hydra DEX
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="https://bridge.hydrachain.org/et"
                        target="_blank"
                      >
                        Hydra Bridge
                      </Link>
                    </li>
                    <li>
                      <Link to="#">Download Mobile App</Link>
                    </li>
                    <li>
                      <Link
                        to="https://play.google.com/store/apps/details?id=com.hydrachain.hydrawallet&hl=en&gl=US&pli=1"
                        target="_blank"
                      >
                        - Android
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="https://apps.apple.com/bw/app/hydra-chain-mobile-wallet/id1592256011"
                        target="_blank"
                      >
                        - iOS
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="">
                <div className="single-footer-widget">
                  <h5>Support</h5>

                  <ul className="footer-links-list">
                    <li>
                      <Link to="/contact">Contact</Link>
                    </li>
                    <li>
                      <Link to="https://docs.hydrachain.org/" target="_blank">
                        Documentation &amp; Guides
                      </Link>
                    </li>
                    <li>
                      <Link to="https://medium.com/hydra-chain" target="_blank">
                        Blog
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="footer-bottom-area">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-6">
              <p>
                Copyright @{currentYear} <strong>LockTrip L.L.C</strong> All
                rights reserved{" "}
              </p>
            </div>

            <div className="col-lg-6 col-md-6">
              <ul>
                <li>
                  <Link to="/privacy-policy">Privacy Policy</Link>
                </li>
                <li>
                  <Link to="/aml-kyc">AML &amp; KYC Policy</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="footer-map">
        <img src={footerMap} alt="footer-logo" />
      </div>
    </footer>
  );
};

export default Footer;
