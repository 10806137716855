import React from "react";
import { Link } from "gatsby";
import { useRecoilState } from "recoil";
import { collapsedState } from "../../utils/recoil-atoms";

const Navbar = () => {
  const [collapsed, setCollapsed] = useRecoilState(collapsedState);

  const toggleNavbar = () => {
    setCollapsed(!collapsed);
  };

  React.useEffect(() => {
    let elementId = document.getElementById("navbar");
    document.addEventListener("scroll", () => {
      if (window.scrollY > 170) {
        elementId.classList.add("is-sticky");
      } else {
        elementId.classList.remove("is-sticky");
      }
    });
    window.scrollTo(0, 0);
  }, []);

  const classOne = collapsed
    ? "collapse navbar-collapse"
    : "collapse navbar-collapse show";
  const classTwo = collapsed
    ? "navbar-toggler navbar-toggler-right collapsed"
    : "navbar-toggler navbar-toggler-right";

  return (
    <div id="navbar" className="navbar-area">
      <div className="container">
        <div className="tarn-nav">
          <div className="">
            <nav className="navbar navbar-expand-lg navbar-light">
              <Link to="https://hydrachain.org/" className="navbar-brand">
                <img
                  src="https://assets-global.website-files.com/6581bc705e494ef36fe68734/65bce39e52dffc94563c0fbc_hydra-main-logo-blue.svg"
                  alt="HydraChain"
                  width="100"
                />
              </Link>
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
